.Auth-pages-left-top-text {
  position: absolute;
  width: auto;
  height: auto;
  bottom: 5px;
  left: 25px;
  color: #f2f2f2;
  opacity: 1;
  font-size: 1.5rem;
  font-family: "Nunito Sans", sans-serif;
}

@media only screen and (max-width: 630px) and (min-width: 601px) {
  .Auth-pages-left-top-text {
    font-size: 1.4rem;
  }
}

.signUp {
  width: 95% !important;
  height: 35px !important;
  bottom: -15% !important;
  margin-top: 40px !important;
  background-color: #286efa;
  border: none !important;
  border-radius: 100px !important;
  color: #fff !important;
  font-family: "Nunito Sans", sans-serif;
  cursor: pointer !important;
}

.icon-user-New-Password,
.icon-user-Confirm-New-Password {
  color: #a7a7a78e;
  position: absolute;
  left: 90%;
  padding-top: 5px;
}

.signUp2 {
  width: 150px;
  height: 30px;
  bottom: -15%;
  margin-top: 30px;
  background-color: #286efa;
  border: none;
  border-radius: 100px;
  color: #fff;
  font-family: "Nunito Sans", sans-serif;
  cursor: pointer;
}

.container-Dont-Received{
    display: flex;
    justify-content: flex-end;
}
.resendMail {
  color: white;
  font-size: 0.8rem;
  padding-top: 10px;
}
.resendMaill {
    color: rgb(74 62 255);
      font-size: 0.8rem;
  padding-top: 10px;
  padding-left: 5px;
}

.resendMaill:hover {
color: white;
}
